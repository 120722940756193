
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { Business as BusinessSchema } from '@/schemas/principal.schemas';
import BusinessDetailsForm from '@/components/forms/account/business/BusinessDetailsForm.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import { Business } from '@/models/BusinessModel';

const initialValues = {
  business_logo: '',
  business_country: 'AU',
} as unknown as Business;

export default defineComponent({
  name: 'business-profile-update-form',
  props: {
    businessProfile: {
      type: Object,
      default: () => {
        return {} as Business;
      },
    },
    formSubmitLoading: {
      type: Boolean,
      default: false,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['saveBusinessProfile', 'updateBusinessProfile', 'editMode'],
  data: () => ({
    // eslint-disable-next-line
    options: [] as any,
    loading: false,
    formData: initialValues,
    form: {} as unknown as typeof Form,
    customUsername: '',
  }),
  mounted() {
    this.form = this.$refs.profileForm as typeof Form;
  },
  components: {
    Form,
    BusinessDetailsForm,
    AddressForm,
  },
  computed: {
    formSchema() {
      return BusinessSchema;
    },
  },
  methods: {
    handleBusinessLogoChange(value) {
      this.setFieldValue('business_logo', value);
    },
    handleBusinessColorPickerChange(value) {
      this.setFieldValue('business_color', value);
    },
    handleBusinessColorPickerActiveChange(value) {
      this.setFieldValue('business_color', value);
    },
    handleBusinessTextColorPickerChange(value) {
      this.setFieldValue('business_text_color', value);
    },
    handleBusinessTextColorPickerActiveChange(value) {
      this.setFieldValue('business_text_color', value);
    },
    handleCountryChange(value) {
      this.setFieldValue('business_country', value);
    },
    handleCountryBlur() {
      this.validateFormField('business_country');
    },
    handleStateChange(value) {
      this.setFieldValue('business_state', value);
    },
    handleStateBlur() {
      this.validateFormField('business_state');
    },
    async submitForm(values) {
      if (this.formSubmitLoading) return;

      await this.handleGenerateCustomLink();

      this.formData = {
        ...this.formData,
        ...values,
        ...{
          custom_username: this.customUsername,
          custom_link:
            process.env.VUE_APP_CUSdTOM_LINK_BASE_URL +
            '/a/' +
            this.customUsername,
        },
      };

      if (
        typeof this.formData.business_logo === 'undefined' &&
        this.formData?.business_logo === null
      ) {
        this.formData.business_logo = this.businessProfile.business_logo
          ? this.businessProfile.business_logo
          : '';
      }

      if (this.updateMode) {
        this.$emit('updateBusinessProfile', this.formData);
      } else {
        this.$emit('saveBusinessProfile', this.formData);
      }
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    cancel() {
      this.$emit('editMode', false);
    },
    async handleGenerateCustomLink() {
      this.customUsername = await this.form.business_name
        ?.replace(/\s+/g, '')
        ?.toLowerCase();
    },
  },
  watch: {
    businessProfile: {
      handler(value) {
        this.formData = {
          ...this.formData,
          ...value,
          ...{
            business_country: value.business_country ?? 'AU',
          },
        };
      },
      immediate: true,
    },
  },
});
