import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-5" }
const _hoisted_2 = { class: "col-lg-6 col-md-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-6 col-md-6" }
const _hoisted_5 = { class: "col-lg-6 col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      required: true,
      name: "business_name",
      containerClass: "col-lg-6 col-md-6",
      label: "Business Name",
      placeholder: "Business Name",
      type: "text"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_FormField, {
            value: _ctx.values.business_color,
            required: true,
            colorFormat: "hex",
            name: "business_color",
            containerClass: "",
            type: "colorpicker",
            label: "Background Color",
            onColorPickerChange: _ctx.handleBusinessColorPickerChange,
            onColorPickerActiveChange: _ctx.handleBusinessColorPickerActiveChange
          }, null, 8, ["value", "onColorPickerChange", "onColorPickerActiveChange"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_FormField, {
            value: _ctx.values.business_text_color,
            required: true,
            colorFormat: "hex",
            name: "business_text_color",
            label: "Text Color",
            containerClass: "",
            type: "colorpicker",
            onColorPickerChange: _ctx.handleBusinessTextColorPickerChange,
            onColorPickerActiveChange: _ctx.handleBusinessTextColorPickerActiveChange
          }, null, 8, ["value", "onColorPickerChange", "onColorPickerActiveChange"])
        ])
      ])
    ]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_email_address",
      containerClass: "col-lg-6 col-md-6",
      label: "Email Address",
      placeholder: "Email Address",
      type: "email"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_website",
      containerClass: "col-lg-6 col-md-6",
      label: "Website",
      placeholder: "example.com",
      type: "text"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_logo",
      containerClass: "col-lg-6 col-md-6",
      label: "Business Logo",
      type: "file",
      onFileUpload: _ctx.handleBusinessLogoChange,
      accept: ".png, .jpg, .jpeg"
    }, null, 8, ["onFileUpload"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "abn",
      containerClass: "col-lg-6 col-md-6",
      label: "ABN",
      placeholder: "ABN",
      type: "text",
      mask: _ctx.abnMasking,
      onKeypress: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNumber($event)))
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "acn",
      containerClass: "col-lg-6 col-md-6",
      label: "ACN",
      placeholder: "ACN",
      type: "text",
      mask: _ctx.acnMasking,
      onKeypress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNumber($event)))
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_phone_number",
      containerClass: "col-lg-6 col-md-6",
      label: "Office Number",
      placeholder: "Office Number",
      type: "text",
      mask: _ctx.phoneMasking,
      onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNumber($event)))
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      name: "business_mobile_number",
      containerClass: "col-lg-6 col-md-6",
      label: "Mobile Number",
      placeholder: "Mobile Number",
      type: "text",
      mask: _ctx.mobileMasking,
      onKeypress: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isNumber($event)))
    }, null, 8, ["mask"])
  ]))
}