
import { defineComponent } from 'vue';
import BusinesProfileForm from '@/views/new-design/pages/Profile/BusinessDetailsForm.vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { mapGetters, mapActions } from 'vuex';
import {
  Actions as BusinessActions,
  Getters as BusinessGetters,
} from '@/store/enums/BusinessEnums';
import { Actions as SystemActions } from '@/store/enums/SystemEnums';
import toasts from '@/utils/toasts';
import parser from '@/utils/parser';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import { Getters } from '@/store/enums/StoreEnums';
import { longUrl, alphaNumeric } from '@/utils/regex';

export default defineComponent({
  name: 'business-details-page',
  components: {
    BusinesProfileForm,
    BusinessLogo,
  },
  data: () => ({
    isEdit: false,
    isEditCustomLink: false,
    isEditReviewLink: false,
    customLinkCopied: false,
    reviewLinkCopied: false,
    generateCustomLink: false,
    saveCustomLink: false,
    saveReviewLink: false,
    updateMode: false,
    formSubmitLoading: false,
    logoData: '',
    customUsername: '',
    reviewLink: '',
  }),
  async mounted() {
    this.loadInit();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      errors: BusinessGetters.GET_ACTION_ERROR,
      defaultBGColor: Getters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: Getters.GET_DEFAULT_TEXT_COLOR,
    }),
    businessCountryName() {
      if (!this.authUser?.business_country) return;

      return parser.country(this.authUser?.business_country);
    },

    businessStateName() {
      if (!this.authUser?.business_state) return;

      return parser.state(this.authUser?.business_state);
    },
    businessLogoSrc() {
      if (!this.authUser?.business_logo) return;

      return parser.state(this.authUser?.business_logo_src);
    },
    customLinkBaseURL() {
      return process.env.VUE_APP_CUSTOM_LINK_BASE_URL + '/a/';
    },
  },
  methods: {
    ...mapActions({
      updateBusiness: BusinessActions.UPDATE_BUSINESS,
      updateBusinessLink: BusinessActions.UPDATE_BUSINESS_LINKS,
      addBusiness: BusinessActions.ADD_BUSINESS,
      getAllCountries: SystemActions.LIST_ALL_COUNTRIES,
      getAllStates: SystemActions.LIST_STATES,
    }),
    loadInit() {
      this.getAllCountries();

      if (typeof this.authUser?.business_country !== 'undefined') {
        this.getAllStates(this.authUser?.business_country);
      }

      if (typeof this.authUser?.custom_username !== 'undefined') {
        this.generateCustomLink = false;
        this.customUsername = this.authUser?.custom_username;
      }

      if (typeof this.authUser?.review_link !== 'undefined') {
        this.reviewLink = this.authUser?.review_link;
      }
    },
    handleEdit(value) {
      this.isEdit = value;
    },

    handleSaveBusinessProfile(formData) {
      if (this.formSubmitLoading) return;
      this.formSubmitLoading = true;

      delete formData.id;

      return this.addBusiness(formData)
        .then(async () => {
          this.isEdit = false;
          this.formSubmitLoading = false;

          toasts.success('Business Profile Successfully Saved');
        })
        .catch(() => {
          this.formSubmitLoading = false;
          this.isEdit = false;
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
    handleUpdateBusinessProfile(formData) {
      if (this.formSubmitLoading) return;
      this.formSubmitLoading = true;

      delete formData.id;

      return this.updateBusiness(formData)
        .then(() => {
          this.isEdit = false;
          this.formSubmitLoading = false;

          toasts.success('Business Profile Successfully Updated');
        })
        .catch(() => {
          this.formSubmitLoading = false;
          this.isEdit = false;
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;

      this.updateMode = false;
      if (
        typeof this.authUser.business_id !== 'undefined' &&
        this.authUser.business_id !== null &&
        this.authUser.business_id !== ''
      ) {
        this.updateMode = true;
      }
    },
    handleEditCustomLink() {
      this.isEditCustomLink = true;
    },
    handleCloseCustomLink() {
      this.isEditCustomLink = false;
    },
    handleEditReviewLink() {
      this.isEditReviewLink = true;
    },
    handleCloseReviewLink() {
      this.isEditReviewLink = false;
      this.generateCustomLink = false;
    },
    copyLink(refDom) {
      let element = {} as unknown as any;
      let textValue = {} as unknown as any;

      if (refDom === 'customLink') {
        this.customLinkCopied = true;
        element = this.$refs.customLink as unknown as any;
        textValue = this.customLinkBaseURL + element.value;
      } else if (refDom === 'reviewLink') {
        this.reviewLinkCopied = true;
        element = this.$refs.reviewLink as unknown as any;
        textValue = element.value;
      }

      if (!navigator.clipboard) {
        element.select();
        element.setSelectionRange(0, 99999);
        document.execCommand('copy');
      } else {
        navigator.clipboard.writeText(textValue).then(() => {
          setTimeout(() => {
            if (refDom === 'customLink') {
              this.customLinkCopied = false;
            } else if (refDom === 'reviewLink') {
              this.reviewLinkCopied = false;
            }
          }, 2000);
        });
      }
    },
    handleGenerateCustomLink() {
      this.customUsername = this.authUser.business_name
        .replace(/\s+/g, '')
        .toLowerCase();
      this.generateCustomLink = true;
    },
    handleSaveCustomLink() {
      if (this.saveCustomLink) return;
      this.saveCustomLink = true;

      const isValidUsername = alphaNumeric.test(this.customUsername);
      if (!isValidUsername) {
        this.saveCustomLink = false;
        return toasts.error(
          '',
          'Username must not contain special characters.'
        );
      }

      const username = this.customUsername.replace(/\s+/g, '').toLowerCase();

      const formData = {
        custom_username: username,
        custom_link: this.customLinkBaseURL + '/a/' + username,
      };

      return this.updateBusinessLink(formData)
        .then((data) => {
          this.saveCustomLink = false;
          this.generateCustomLink = false;
          this.isEditCustomLink = false;
          this.customUsername = data.custom_username;

          toasts.success('Custom Link Successfully Saved');
        })
        .catch(() => {
          this.saveCustomLink = false;
          this.generateCustomLink = false;
          this.isEditCustomLink = false;
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
    handleSaveReviewLink() {
      if (this.saveReviewLink) return;
      this.saveReviewLink = true;

      const isValidUrl = longUrl.test(this.reviewLink);
      if (!isValidUrl) {
        this.saveReviewLink = false;
        return toasts.error('', 'Please enter a valid url.');
      }

      const formData = {
        review_link: this.reviewLink.toLowerCase(),
      };

      return this.updateBusinessLink(formData)
        .then((data) => {
          this.saveReviewLink = false;
          this.isEditReviewLink = false;
          this.reviewLink = data.review_link;

          toasts.success('Review Link Successfully Saved');
        })
        .catch(() => {
          this.saveCustomLink = false;
          this.generateCustomLink = false;
          this.isEditCustomLink = false;
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
  },
});
